
<template>
  <div
    class="flex items-center text-teal ml-5 mt-3 cursor-pointer w-24"
    @click="reRoute"
  >
    <span class="material-icons-outlined"> arrow_back </span>
    go Back
  </div>
  <transition appear>
    <div class="mt-10 w-10/12 m-auto">
      <div class="p-5">
        <dropdown-search-filter title="Search Offers" :mainFilter="mainFilter"
          :secondaryFilters="secondaryFilters" :inputPlaceHolder="'please input'" @search="searchFunc"
          @reset="acceptedCandidates(activeTab)" @selectedMainFilter="mainFilterSelected" />
      </div>
      <div class="mb-4 border-b w-11/12 mx-auto mt-10">
    <ul class="flex flex-wrap -mb-px">
      <li class="mr-2" v-for="item in tabs" :key="item.value">
        <button
          class="
            inline-block
            py-4
            px-4
            text-sm
            font-medium
            text-center
            rounded-t-lg
            border-b-2 border-transparent
            hover:text-teal hover:border-teal
          "
          :class="
            activeTab === item.value ? 'text-teal border-teal' : 'text-grey'
          "
          @click="acceptedCandidates(item.value)"
        >
          {{ item.title }}
        </button>
      </li>
    </ul>
  </div>
      <div
        class="
          w-11/12
          mx-auto
          text-center text-grey
          flex flex-col flex-grow
        "
      >
        <template v-if="loading == false">
          <el-table
            header-row-class-name="bg-gray-200"
            header-cell-class-name="text-teal py-5"
            :cell-style="{ padding: '10px' }"
            row-class-name="border-t"
            class="w-full rounded-xl overflow-hidden bg-gray-100 flex flex-grow"
            :data="acceptedCandidatesArray"
            style="width: 100%"
          >
            <el-table-column
              v-for="item in tableHeaders"
              :key="item.index"
              :prop="item.prop"
              :label="item.title"
            >
              <template #default="scope">
                <div class="" v-if="item.id === 'fullname'">
                  {{
                    scope.row.profile.first_name
                  }}
                  {{ 
                    scope.row.profile.last_name
                  }}
                </div>
                <div class="" v-if="item.id === 'date'">
                  {{
                    new Date(scope.row.created_at).toLocaleDateString("en-GB")
                  }}
                </div>
                <div class="" v-if="item.id === 'actions'">
                  <table-options-dropdown
                    :id="scope.row.profile.user_id"
                    :options=" (activeTab === 3 || activeTab === 2) ? tabledropdownOptions : tabledropdownOptions2"
                    :dynamicParam="scope.row.id"
                    :dynamicParam2="scope.row.profile.hiring_request_id"
                  >
                    <span class="material-icons"> more_horiz </span>
                  </table-options-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <table-skeleton />
        </template>
      </div>
    </div>
  </transition>
</template>
  
  <script>
import dropdownSearchFilter from "@/components/ui/baseComponents/dropdownSearchFilter.vue";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";

export default {
  components: {
    dropdownSearchFilter,
    tableOptionsDropdown,
  },

  data() {
    return {
      loading: true,
      acceptedCandidatesArray: [],
      tableHeaders: [
        { title: "Candidate Name", prop: "", id: "fullname" },
        { title: "Email", prop: "email", id: "email" },
        { title: "Application Date", prop: "department_id", id: "date" },
        {
          title: "Job Title",
          prop: "position_summary.job_title",
          id: "interview_date",
        },
        { title: "Actions", prop: "item", id: "actions" },
      ],
      tabledropdownOptions: [
        {
          type: "link",
          linkName: "re-manage-vacancy-new-offer",
          paramName: "userId",
          paramName2: "manageVacancy",
          label: "View Offer",
        },
        {
          type: "link",
          linkName: "re-ammend-offer",
          paramName: "userId",
          paramName2: "manageVacancy",
          label: "Ammend Offer",
        },
      ],
        tabledropdownOptions2: [
        {
          type: "link",
          linkName: "re-manage-vacancy-new-offer",
          paramName: "userId",
          paramName2: "manageVacancy",
          label: "View Offer",
        },
      ],
      activeTab: 2,
      tabs: [
        { title: "Offers Made", value: 2 },
        {
          title: "Offers Accepted", 
          value: 1,
        },
        {
          title: "Offers Revision",
          value: 3,
        },
        {
          title: "Offers Declined",
          value: 0,
        },
      ],
      mainFilter: [
        {
          label: "Role",
          value: "role",
          type: "dropdown",
        },
        {
          label: "Email",
          value: "email",
          type: "input",
        },
        {
          label: "Last Name",
          value: "last_name",
          type: "input",
        },
        {
          label: "Location",
          value: "location",
          type: "dropdown",
        },
      ],
      secondaryFilters: [],
      practiceData: [],
    };
  },

  async created() {
    await this.acceptedCandidates(2);
    await this.fetchRoles();
    await this.loadPractices();
  },

  methods: {
    reRoute() {
      this.$router.back();
    },
    async acceptedCandidates(value) {
      this.activeTab = value;
      const filterCandidates = await this.$store.dispatch(
        "InterviewProcess/acceptedCandidates",
        {
          offer_status: value,
        }
      );
      this.acceptedCandidatesArray = filterCandidates.data.users.data;
      this.loading = false;
    },
    async searchFunc({ filter, value }) {
        this.isLoading = true;
        const filterCandidates = await this.$store.dispatch(
        "InterviewProcess/acceptedCandidates",
        {
            applicant_status: this.activeTab,
            page: 1,
            [filter.value]: value.value ? value.value : value.code ? value.code : value,
          }
      );
      this.acceptedCandidatesArray = filterCandidates.data.users.data;
      this.totalCandidates = filterCandidates.data.users.total;
      this.loading = false;
    },
    mainFilterSelected({ value }) {
      if (value === "role") {
        this.secondaryFilters = this.getRoles;
        return;
      }
      if (value === "location") {
        this.secondaryFilters = this.getPractices;
        return;
      }
    },
    async fetchRoles() {
      try {
        await this.$store.dispatch("ReLocums/fetchRoles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadPractices() {
      try {
        await this.$store.dispatch("admin/loadPractices");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },

  computed: {
    getRoles() {
      return this.$store.getters["ReLocums/getRoles"];
    },
    getPractices() {
      const data = this.$store.getters["admin/getPractices"];
      if (Object.keys(data).length !== 0) {
        data.forEach((item) => {
          this.practiceData.push({ code: item.id, label: item.practice_name });
        });
        return this.practiceData;
      } else {
        return this.practiceData;
      }
    },
  },
};
</script>
  